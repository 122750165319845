export default Object.freeze([
  {
    label: intl.t('first-property'),
    value: 'first-property',
  },
  {
    label: intl.t('second-property'),
    value: 'second-property',
  },
  {
    label: intl.t('investor'),
    value: 'investor',
  },

]);
