import FilterBetween from 'neuro-frontend/utils/filter-operation/filter-between';
import FilterIs from 'neuro-frontend/utils/filter-operation/filter-is';

export default [
  {
    groupName: intl.t('intranet-saft-index.filters'),
    options: [
      {
        label: intl.t('date'),
        operation: new FilterBetween({
          appendDates: 'Date',
        }),
        value: 'date',
      },
      {
        label: intl.t('commerce'),
        model: 'commerces',
        modelLabel: 'name',
        modelValue: 'id',
        operation: new FilterIs({
          onlyPositive: true,
        }),
        value: 'commerce',
      },
    ],
  },
];
