export default Object.freeze([
  {
    includes: ['cms'],
    label: intl.t('id'),
    value: 'id',
  },
  {
    includes: ['cms'],
    label: intl.t('center-id'),
    value: 'center_id',
  },
  {
    includes: ['cms'],
    label: intl.t('email'),
    value: 'email',
  },
  {
    includes: ['cms'],
    label: intl.t('level'),
    value: 'level',
  },
  {
    includes: ['cms'],
    label: intl.t('register-date'),
    value: 'register_date',
  },
  {
    includes: ['cms'],
    label: intl.t('bdate'),
    value: 'bdate',
  },
  {
    includes: ['cms'],
    label: intl.t('sex'),
    value: 'sex',
  },
  {
    includes: ['cms'],
    label: intl.t('cp'),
    value: 'cp',
  },
  {
    includes: ['cms'],
    label: intl.t('visit-type'),
    value: 'mstatus',
  },
  {
    includes: ['cms'],
    label: intl.t('name'),
    value: 'name',
  },
  {
    includes: ['cms'],
    label: intl.t('eula'),
    value: 'eula',
  },
  {
    includes: ['cms'],
    label: intl.t('last-activity'),
    value: 'last_activity',
  },
  {
    includes: ['cms'],
    label: intl.t('loyalty-card'),
    value: 'loyalty_card',
  },
  {
    includes: ['cms'],
    label: intl.t('last-name'),
    value: 'last_name',
  },
  {
    includes: ['cms'],
    label: intl.t('address'),
    value: 'address',
  },
  {
    includes: ['cms'],
    label: intl.t('dni'),
    value: 'dni',
  },
  {
    includes: ['cms'],
    label: intl.t('phone'),
    value: 'phone',
  },
  {
    includes: ['cms'],
    label: intl.t('app-notifications'),
    value: 'app_notifications',
  },
  {
    includes: ['cms'],
    label: intl.t('mail-notifications'),
    value: 'mail_notifications',
  },
  {
    includes: ['cms'],
    label: intl.t('province'),
    value: 'province',
  },
  {
    includes: ['cms'],
    label: intl.t('city'),
    value: 'city',
  },
  {
    includes: ['cms'],
    label: intl.t('children'),
    value: 'children',
  },
  {
    includes: ['cms'],
    label: intl.t('usertypes'),
    value: 'usertypes',
  },
  {
    includes: ['cms'],
    label: intl.t('campaigns'),
    value: 'campaigns',
  },
  {
    includes: ['cms'],
    label: intl.t('receipts'),
    value: 'receipts',
  },
]);
