export default Object.freeze([
  {
    label: intl.t('dinamic-action'),
    value: 'true',
  },
  {
    label: intl.t('fixed-action'),
    value: 'false',
  },
]);
