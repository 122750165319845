export default Object.freeze([
    {
        label: intl.t('mof-type.custom'),
        value: 'custom',
    },
    {
        label: intl.t('mof-type.standard'),
        value: 'menu_static',
    },
]);
