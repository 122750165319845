import FilterIs from 'neuro-frontend/utils/filter-operation/filter-is';
import FilterBoolean from 'neuro-frontend/utils/filter-operation/filter-boolean';
import proposalStatus from './proposal-status';
import proposalTypes from './proposal-types';

export default [
  {
    groupName: intl.t('proposal.filters'),
    options: [
      {
        label: intl.t('status'),
        model: proposalStatus,
        operation: new FilterIs({
        }),
        value: 'state',
      },
      {
        label: intl.t('proposal.type'),
        model: proposalTypes,
        operation: new FilterIs({
        }),
        value: 'proposal-type',
      },
      {
        label: intl.t('category'),
        model: 'categories',
        modelName: 'category',
        modelLabel: 'name',
        modelValue: 'id',
        operation: new FilterIs({
          centerFilterKey: 'center_id',
          values: [
            {
              label: intl.t('name'),
              value: 'value',
              type: 'search',
            },
          ],
        }),
        value: 'category',
      },
      {
        label: intl.t('store'),
        model: 'commerces',
        modelLabel: 'name',
        modelName: 'commerce',
        modelValue: 'id',
        operation: new FilterIs({
          centerFilterKey: 'center_id',
          onlyPositive: true,
          values: [
            {
              label: intl.t('name'),
              value: 'value',
              type: 'search',
            },
          ],
        }),
        value: 'commerce',
      },
      {
        label: intl.t('proposal.is-coupon'),
        operation: new FilterBoolean(),
        value: 'is_coupon',
      },
      {
        label: intl.t('proposal.is-poll'),
        operation: new FilterBoolean(),
        value: 'is_poll',
      },
    ],
  },
];
