export default Object.freeze([
  {
    label: intl.t('crm.filters-section.dates'),
    code: 'dates',
    icon: 'date_range',
  },
  {
    label: intl.t('crm.filters-section.source'),
    code: 'source',
    icon: 'crop',
  },
  {
    label: intl.t('crm.filters-section.general-data'),
    code: 'general_data',
    icon: 'assessment',
  },
  {
    label: intl.t('crm.filters-section.children'),
    code: 'children',
    icon: 'toys',
  },
  {
    label: intl.t('crm.filters-section.demographic'),
    code: 'demographic',
    icon: 'people',
  },
  {
    label: intl.t('crm.filters-section.communications'),
    code: 'communications',
    icon: 'rss_feed',
  },
  {
    label: intl.t('crm.filters-section.behavior-and-type'),
    code: 'behaviour',
    icon: 'style',
  },
  {
    label: intl.t('crm.filters-section.loyalty'),
    code: 'loyalty',
    icon: 'loyalty',
  },
  {
    label: intl.t('crm.filters-section.sales'),
    code: 'sales',
    icon: 'shopping_basket',
  },
  {
    label: intl.t('crm.filters-section.email_engagement'),
    code: 'email',
    icon: 'mail',
  },
  {
    label: intl.t('crm.filters-section.proposals-engagement'),
    code: 'proposals',
    icon: 'favorite',
  },
  {
    label: intl.t('crm.filters-section.data-quality'),
    code: 'data_quality',
    icon: 'photo_size_select_small',
  },
  // {
  //   label: intl.t('crm.filters-section.push-notifications-engagement'),
  //   code: 'push_notifications_engagement',
  //   icon: 'notifications_active',
  // },
  {
    label: intl.t('crm.filters-section.foreground_location'),
    code: 'foreground_location',
    icon: 'contacts',
    modules: ['foregroundLocation'],
  },
]);
