export default Object.freeze([
  {
    label: intl.t('stamp.status.redeemed'),
    value: 'redeemed',
    color: '#2477b5',
  },
  {
    label: intl.t('stamp.status.available'),
    value: 'available',
    color: '#028a3d',
  },
  {
    label: intl.t('stamp.status.unavailable'),
    value: 'unavailable',
    color: '#e80042',
  },
]);
