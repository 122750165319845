export default Object.freeze([
  {
    label: intl.t('configuration.extra-attributes.list.all'),
    value: 'all',
  },
  {
    label: intl.t('configuration.extra-attributes.list.commerce'),
    value: 'commerce',
  },
  {
    label: intl.t('configuration.extra-attributes.list.proposal'),
    value: 'proposal',
  },
]);
