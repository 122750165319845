export default Object.freeze([
  {
    label: intl.t('dashboard.category.general'),
    value: 'general',
  },
  {
    label: intl.t('dashboard.category.home'),
    value: 'home',
  },
  {
    label: intl.t('dashboard.category.traffic'),
    value: 'traffic',
  },
]);
