export default Object.freeze([
  {
    label: intl.t('push-notification.active'),
    value: 'active',
    color: '#028a3d',
  },
  {
    label: intl.t('push-notification.archived'),
    value: 'archived',
    color: '#2477b5',
  },
  {
    label: intl.t('push-notification.scheduled'),
    value: 'scheduled',
    color: '#e80042',
  },
  {
    label: intl.t('push-notification.draft'),
    value: 'draft',
    color: '#8E6506',
  },
]);
