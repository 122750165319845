export default Object.freeze([
  {
    label: intl.t('january'),
    value: 'january',
  },
  {
    label: intl.t('february'),
    value: 'february',
  },
  {
    label: intl.t('march'),
    value: 'march',
  },
  {
    label: intl.t('april'),
    value: 'april',
  },
  {
    label: intl.t('may'),
    value: 'may',
  },
  {
    label: intl.t('june'),
    value: 'june',
  },
  {
    label: intl.t('july'),
    value: 'july',
  },
  {
    label: intl.t('august'),
    value: 'august',
  },
  {
    label: intl.t('september'),
    value: 'september',
  },
  {
    label: intl.t('october'),
    value: 'october',
  },
  {
    label: intl.t('november'),
    value: 'november',
  },
  {
    label: intl.t('december'),
    value: 'december',
  },
]);
