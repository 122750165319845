export default Object.freeze([
  {
    label: intl.t('email-campaign.single-sending'),
    value: 'single-sending',
  },
  {
    label: intl.t('email-campaign.every-15-minutes'),
    value: '15min',
  },
  {
    label: intl.t('email-campaign.every-30-minutes'),
    value: '30min',
  },
  {
    label: intl.t('email-campaign.hourly'),
    value: 'hourly',
  },
  {
    label: intl.t('email-campaign.daily'),
    value: 'daily',
  },
  {
    label: intl.t('email-campaign.weekly'),
    value: 'weekly',
  },
]);
