import FilterBetween from 'neuro-frontend/utils/filter-operation/filter-between';
import FilterIs from 'neuro-frontend/utils/filter-operation/filter-is';
import campaignStatus from './campaign-status';
import campaignTypes from './campaign-types';

export default [
  {
    groupName: intl.t('campaign.filters'),
    options: [
      {
        label: intl.t('campaigntype'),
        model: campaignTypes,
        operation: new FilterIs({
          onlyPositive: true,
        }),
        value: 'campaign-type',
      },
      {
        label: intl.t('status'),
        model: campaignStatus,
        operation: new FilterIs({
          onlyPositive: true,
        }),
        value: 'status',
      },

      {
        label: intl.t('date'),
        operation: new FilterBetween({
          appendDates: 'Datetime',
        }),
        value: 'date',
      },
      {
        label: intl.t('rating'),
        operation: new FilterBetween({
          onlyPositive: true,

          values: [
            {
              label: intl.t('value'),
              type: 'number',
              value: 'min',
            },
            {
              label: intl.t('and'),
              type: 'static-text',
            },
            {
              label: intl.t('value'),
              type: 'number',
              value: 'max',
            },
            {
              label: intl.t('stars'),
              type: 'static-text',
            },
          ],
        }),
        value: 'rating',
      },
    ],
  },
];
