export default Object.freeze([
  {
    label: intl.t('lead'),
    value: 'lead',
  },
  {
    label: intl.t('active'),
    value: 'active',
  },
  {
    label: intl.t('removed'),
    value: 'removed',
  },
]);
