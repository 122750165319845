export default Object.freeze([
  {
    label: intl.t('leasing.accessories'),
    value: 'accessories',
  },
  {
    label: intl.t('leasing.books'),
    value: 'books',
  },
  {
    label: intl.t('leasing.bowling'),
    value: 'bowling',
  },
  {
    label: intl.t('leasing.childrens-apparel'),
    value: 'childrens-apparel',
  },
  {
    label: intl.t('leasing.cinema'),
    value: 'cinema',
  },
  {
    label: intl.t('leasing.coffeeshop-icecream'),
    value: 'coffeeshop-icecream',
  },
  {
    label: intl.t('leasing.electronics'),
    value: 'electronics',
  },
  {
    label: intl.t('leasing.fast-food'),
    value: 'fast-food',
  },
  {
    label: intl.t('leasing.food'),
    value: 'food',
  },
  {
    label: intl.t('leasing.furniture'),
    value: 'furniture',
  },
  {
    label: intl.t('leasing.hairdressers-and-beauty-services'),
    value: 'hairdressers-and-beauty-services',
  },
  {
    label: intl.t('leasing.handbags'),
    value: 'handbags',
  },
  {
    label: intl.t('leasing.home'),
    value: 'home',
  },
  {
    label: intl.t('leasing.home-apparel'),
    value: 'home-apparel',
  },
  {
    label: intl.t('leasing.jewelry-watches'),
    value: 'jewelry-watches',
  },
  {
    label: intl.t('leasing.lingerie'),
    value: 'lingerie',
  },
  {
    label: intl.t('leasing.mens-apparel'),
    value: 'mens-apparel',
  },
  {
    label: intl.t('leasing.opticians-farmacy'),
    value: 'opticians-farmacy',
  },
  {
    label: intl.t('leasing.other-leisure'),
    value: 'other-leisure',
  },
  {
    label: intl.t('leasing.other-services'),
    value: 'other-services',
  },
  {
    label: intl.t('leasing.outlet'),
    value: 'outlet',
  },
  {
    label: intl.t('leasing.perfumery-beauty-store'),
    value: 'perfumery-beauty-store',
  },
  {
    label: intl.t('leasing.pets'),
    value: 'pets',
  },
  {
    label: intl.t('leasing.phone-photo-games'),
    value: 'phone-photo-games',
  },
  {
    label: intl.t('leasing.restaurants'),
    value: 'restaurants',
  },
  {
    label: intl.t('leasing.shoes'),
    value: 'shoes',
  },
  {
    label: intl.t('leasing.special-food'),
    value: 'special-food',
  },
  {
    label: intl.t('leasing.speciality-gift'),
    value: 'speciality-gift',
  },
  {
    label: intl.t('leasing.sports'),
    value: 'sports',
  },
  {
    label: intl.t('leasing.supermarket'),
    value: 'supermarket',
  },
  {
    label: intl.t('leasing.toys'),
    value: 'toys',
  },
  {
    label: intl.t('leasing.travels'),
    value: 'travels',
  },
  {
    label: intl.t('leasing.womens-mens-apparel'),
    value: 'womens-mens-apparel',
  },
  {
    label: intl.t('leasing.womens-apparel'),
    value: 'womens-apparel',
  },
  {
    label: intl.t('leasing.young-apparel'),
    value: 'young-apparel',
  },
]);
