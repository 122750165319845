export default Object.freeze([
  {
    label: intl.t('zone-1'),
    value: '1',
  },
  {
    label: intl.t('zone-2'),
    value: '2',
  },
  {
    label: intl.t('zone-3'),
    value: '3',
  },
  {
    label: intl.t('zone-4'),
    value: '4',
  },
]);
