export default Object.freeze([
    {
      label: intl.t('level.low'),
      value: 'low',
    },
    {
      label: intl.t('level.medium-low'),
      value: 'medium-low',
    },
    {
      label: intl.t('level.medium-high'),
      value: 'medium-high',
    },
    {
      label: intl.t('level.high'),
      value: 'high',
    },
]);
