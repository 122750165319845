export default Object.freeze([
  {
    label: intl.t('is_not_received'),
    value: 'is_not',
  },
  {
    label: intl.t('is_received'),
    value: 'is',
  },
]);
