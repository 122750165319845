export default Object.freeze([
  {
    label: intl.t('opened'),
    value: 'opened',
  },
  {
    label: intl.t('not_opened'),
    value: 'not_opened',
  },
  {
    label: intl.t('clicked'),
    value: 'clicked',
  },
  {
    label: intl.t('not_clicked'),
    value: 'not_clicked',
  },
]);
