export default Object.freeze([
  {
    label: intl.t('push-notification.action-type.default'),
    value: 'default',
  },
  {
    label: intl.t('push-notification.action-type.proposal'),
    value: 'proposal',
  },
]);
