export default Object.freeze([
  {
    label: intl.t('cms.mof.permissions.camera'),
    value: 'camera',
  },
  {
    label: intl.t('cms.mof.permissions.external-storage'),
    value: 'external_storage',
  },
]);
