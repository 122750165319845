export default Object.freeze([
  {
    label: intl.t('actions.call'),
    value: 'call',
    type: 'manual',
  },
  {
    label: intl.t('actions.visit'),
    value: 'visit',
    type: 'manual',
  },
  {
    label: intl.t('actions.email'),
    value: 'email',
    type: 'manual',
  },
  {
    label: intl.t('actions.form'),
    value: 'form',
    type: 'manual',
  },
  {
    label: intl.t('actions.sent_marketing_info'),
    defaultTitle: intl.t('actions.sent_marketing_info_title'),
    value: 'sent_marketing_info',
    type: 'auto',
  },
  {
    label: intl.t('actions.reservation'),
    defaultTitle: intl.t('actions.reservation_title'),
    value: 'reservation',
    type: 'auto',
  },
  {
    label: intl.t('actions.rejection'),
    defaultTitle: intl.t('actions.rejection_title'),
    value: 'rejection',
    type: 'auto',
  },
  {
    label: intl.t('actions.signed'),
    defaultTitle: intl.t('actions.signed_title'),
    value: 'signed',
    type: 'auto',
  },
]);
