import FilterIs from 'neuro-frontend/utils/filter-operation/filter-is';

export default [
  {
    groupName: intl.t('suppliers.filters'),
    options: [
      {
        label: intl.t('service'),
        model: 'services',
        modelLabel: 'name',
        modelValue: 'id',
        operation: new FilterIs({
          onlyPositive: true,
        }),
        value: 'services',
      },
    ],
  },
];
