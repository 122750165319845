import FilterIs from 'neuro-frontend/utils/filter-operation/filter-is';

export default [
  {
    groupName: intl.t('proposal.filters'),
    options: [
      {
        label: intl.t('filters.segment-categories.name'),
        model: 'segmentCategory',
        modelName: 'segmentCategory',
        modelLabel: 'name',
        modelValue: 'id',
        operation: new FilterIs({
          centerFilterKey: 'center_id',
          onlyPositive: true,
          values: [
            {
              label: intl.t('name'),
              value: 'value',
              type: 'search',
            },
          ],
        }),
        value: 'segment-category',
      },
    ],
  },
];
