export default Object.freeze([
  {
    label: intl.t('center'),
    value: 'center',
  },
  {
    label: intl.t('store'),
    value: 'commerce',
  },
]);
