import FilterIs from 'neuro-frontend/utils/filter-operation/filter-is';
import buttonMofTypes from './button-mof-types';

export default [
    {
        groupName: intl.t('mofs.filters'),
        options: [
            {
                label: intl.t('type'),
                model: buttonMofTypes,
                operation: new FilterIs({
                    onlyPostitive: true,
                }),
                value: 'view-type',
            },
        ],
    },
];
