export default Object.freeze([
  {
    label: intl.t('viewed'),
    value: 'viewed',
  },
  {
    label: intl.t('liked'),
    value: 'liked',
  },
  {
    label: intl.t('shared'),
    value: 'shared',
  },
  {
    label: intl.t('scans'),
    value: 'scans',
  },
  {
    label: intl.t('votes'),
    value: 'votes',
  },
]);
