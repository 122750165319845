export default Object.freeze([
  {
    label: intl.t('physical-person'),
    value: 'natural',
  },
  {
    label: intl.t('legal-person'),
    value: 'legal',
  },
]);
