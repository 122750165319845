import FilterBetween from 'neuro-frontend/utils/filter-operation/filter-between';
import FilterBoolean from 'neuro-frontend/utils/filter-operation/filter-boolean';
import FilterContains from 'neuro-frontend/utils/filter-operation/filter-contains';
import FilterIs from 'neuro-frontend/utils/filter-operation/filter-is';
import FilterOrMore from 'neuro-frontend/utils/filter-operation/filter-or-more';
import FilterCustomOperation from 'neuro-frontend/utils/filter-operation/filter-custom-operation';
import UserEmailEngagementOptions from './user-email-engagement-options';
import UserProposalEngagementOptions from './user-proposal-engagement-options';

import receivesFilterIs from './receives-filter-is';
import influenceZones from './influence-zones';
import visitTypes from './visit-types';
import sexes from './sexes';

export default Object.freeze([
  {
    groupFilterCode: 'dates',
    label: intl.t('register-date'),
    modules: ['CRM', 'offices'],
    operation: new FilterBetween({
      appendDates: 'Datetime',
    }),
    value: 'register_dates',
  },
  {
    groupFilterCode: 'source',
    label: intl.t('store'),
    modules: ['CRM', 'offices'],
    modelName: 'commerce',
    modelLabel: 'name',
    modelValue: 'id',
    operation: new FilterIs({
      centerFilterKey: 'center_id',
      values: [
        {
          label: intl.t('name'),
          value: 'value',
          type: 'search',
        },
      ],
    }),
    value: 'commerces',
  },
  {
    groupFilterCode: 'general_data',
    label: intl.t('name'),
    modules: ['CRM', 'offices'],
    operation: new FilterContains(),
    value: 'name',
  },
  {
    groupFilterCode: 'general_data',
    label: intl.t('phone'),
    modules: ['CRM'],
    operation: new FilterContains(),
    value: 'phone',
  },
  {
    groupFilterCode: 'children',
    label: intl.t('user.single_parent'),
    modules: ['CRM'],
    operation: new FilterBoolean(),
    value: 'single_parent',
  },
  {
    groupFilterCode: 'children',
    label: intl.t('user.large-family'),
    modules: ['CRM'],
    operation: new FilterBoolean(),
    value: 'large_family',
  },
  {
    groupFilterCode: 'general_data',
    label: intl.t('last-name'),
    modules: ['CRM', 'offices'],
    operation: new FilterContains(),
    value: 'last_name',
  },
  {
    groupFilterCode: 'general_data',
    label: intl.t('dni'),
    modules: ['CRM', 'offices'],
    operation: new FilterContains(),
    value: 'dni',
  },
  {
    groupFilterCode: 'demographic',
    label: intl.t('sex'),
    model: sexes,
    modules: ['CRM', 'offices'],
    operation: new FilterIs(),
    value: 'sex',
  },
  {
    groupFilterCode: 'demographic',
    label: intl.t('crm.users.is-senior'),
    modules: ['CRM'],
    operation: new FilterBoolean(),
    value: 'senior',
  },
  {
    groupFilterCode: 'demographic',
    label: intl.t('age'),
    modules: ['CRM'],
    operation: new FilterBetween({
      onlyPositive: true,
    }),
    value: 'age',
  },
  {
    groupFilterCode: 'demographic',
    label: intl.t('province_id'),
    modelName: 'province',
    modelLabel: 'name',
    modelValue: 'id',
    modules: ['CRM', 'offices'],
    operation: new FilterIs({
      centerFilterKey: 'none',
      values: [
        {
          label: intl.t('name'),
          value: 'value',
          type: 'search',
        },
      ],
    }),
    value: 'provinces',
  },
  {
    groupFilterCode: 'demographic',
    label: intl.t('city'),
    modules: ['CRM', 'offices'],
    operation: new FilterContains(),
    value: 'city',
  },
  {
    groupFilterCode: 'demographic',
    label: intl.t('cp'),
    modules: ['CRM', 'offices'],
    operation: new FilterContains(),
    value: 'cp',
  },
  {
    groupFilterCode: 'communications',
    label: intl.t('has-valid-email'),
    modules: ['notEmailValidation'],
    operation: new FilterBoolean(),
    value: 'has_email',
  },
  {
    groupFilterCode: 'communications',
    label: intl.t('has-validated-email'),
    modules: ['emailValidation'],
    operation: new FilterBoolean(),
    value: 'email_validated',
  },
  {
    groupFilterCode: 'general_data',
    label: intl.t('email'),
    modules: ['CRM', 'offices'],
    operation: new FilterContains(),
    value: 'email',
  },
  {
    groupFilterCode: 'communications',
    label: intl.t('mail_notifications'),
    modules: ['CRM', 'offices'],
    operation: new FilterBoolean(),
    value: 'mail_notifications',
  },
  {
    groupFilterCode: 'communications',
    label: intl.t('app-notifications'),
    modules: ['CRM'],
    operation: new FilterBoolean(),
    value: 'app_notifications',
  },
  {
    groupFilterCode: 'communications',
    label: intl.t('mail_partners_notifications'),
    modules: ['CRM', 'offices'],
    operation: new FilterBoolean(),
    value: 'mail_partners_notifications',
  },
  {
    groupFilterCode: 'demographic',
    label: intl.t('visit-type'),
    model: visitTypes,
    modules: ['CRM', 'offices'],
    operation: new FilterIs(),
    value: 'mstatus',
  },
  {
    groupFilterCode: 'demographic',
    label: intl.t('active-work-influence-zone'),
    model: influenceZones,
    modules: ['CRM'],
    operation: new FilterIs(),
    value: 'influence_zone_work',
  },
  {
    groupFilterCode: 'demographic',
    label: intl.t('active-home-influence-zone'),
    model: influenceZones,
    modules: ['CRM'],
    operation: new FilterIs(),
    value: 'influence_zone_home',
  },
  {
    groupFilterCode: 'source',
    label: intl.t('influence-zone'),
    model: influenceZones,
    modules: ['CRM'],
    operation: new FilterIs(),
    value: 'influence_zone',
  },
  {
    groupFilterCode: 'dates',
    label: intl.t('bdate'),
    modules: ['CRM'],
    operation: new FilterBetween({
      appendDates: 'Date',
    }),
    value: 'bdate',
  },
  {
    groupFilterCode: 'children',
    label: intl.t('user.child-bday'),
    modules: ['CRM'],
    operation: new FilterBetween({
      values: [
        {
          label: intl.t('following'),
          type: 'static-text',
        },
        {
          label: intl.t('value'),
          type: 'number',
          value: 'min',
        },
        {
          label: intl.t('and'),
          type: 'static-text',
        },
        {
          label: intl.t('value'),
          type: 'number',
          value: 'max',
        },
        {
          label: intl.t('days'),
          type: 'static-text',
        },
      ],
    }),
    value: 'children_birthday_days',
  },
  {
    groupFilterCode: 'children',
    label: intl.t('user.child-in-play-center-days'),
    modules: ['CRM'],
    operation: new FilterBetween({
      values: [
        {
          label: intl.t('in_the_last'),
          type: 'static-text',
        },
        {
          label: intl.t('value'),
          type: 'number',
          value: 'min',
        },
        {
          label: intl.t('and'),
          type: 'static-text',
        },
        {
          label: intl.t('value'),
          type: 'number',
          value: 'max',
        },
        {
          label: intl.t('days'),
          type: 'static-text',
        },
      ],
    }),
    value: 'children_in_play_center_last_days',
  },
  {
    format: 'MM-DD',
    groupFilterCode: 'children',
    label: intl.t('crm.users.children-bdate-month-day'),
    modules: ['CRM'],
    operation: new FilterBetween({
      values: [
        {
          type: 'date',
          value: 'start',
        },
        {
          type: 'date',
          value: 'end',
        },
      ],
    }),
    value: 'children_birthday_month_day',
  },
  {
    format: 'MM-DD',
    groupFilterCode: 'dates',
    label: intl.t('crm.users.bdate-month-day'),
    modules: ['CRM'],
    operation: new FilterBetween({
      values: [
        {
          type: 'date',
          value: 'start',
        },
        {
          type: 'date',
          value: 'end',
        },
      ],
    }),
    value: 'bdate_month_day',
  },
  {
    groupFilterCode: 'dates',
    label: intl.t('crm.users.bdate-today'),
    modules: ['CRM'],
    operation: new FilterBoolean(),
    value: 'bdate_today',
  },
  {
    groupFilterCode: 'dates',
    label: intl.t('user.bday-days'),
    modules: ['CRM'],
    operation: new FilterBetween({
      values: [
        {
          label: intl.t('following'),
          type: 'static-text',
        },
        {
          label: intl.t('value'),
          type: 'number',
          value: 'min',
        },
        {
          label: intl.t('and'),
          type: 'static-text',
        },
        {
          label: intl.t('value'),
          type: 'number',
          value: 'max',
        },
        {
          label: intl.t('days'),
          type: 'static-text',
        },
      ],
    }),
    value: 'birthday_days',
  },
  {
    groupFilterCode: 'children',
    label: intl.t('children_number'),
    modules: ['CRM', 'offices'],
    operation: new FilterBetween(),
    value: 'children',
  },
  {
    groupFilterCode: 'children',
    label: intl.t('children_age'),
    modules: ['CRM'],
    operation: new FilterBetween({
      values: [
        {
          type: 'number',
          value: 'min',
        },
        {
          label: intl.t('and'),
          type: 'static-text',
        },
        {
          type: 'number',
          value: 'max',
        },
        {
          label: intl.t('years'),
          type: 'static-text',
        },
      ],
    }),
    value: 'children_age',
  },
  {
    groupFilterCode: 'demographic',
    label: intl.t('nationality_id'),
    modelName: 'country',
    modelLabel: 'name',
    modelValue: 'id',
    modules: ['CRM', 'offices'],
    operation: new FilterIs({
      centerFilterKey: 'none',
      values: [
        {
          label: intl.t('name'),
          value: 'value',
          type: 'search',
        },
      ],
    }),
    value: 'nationalities',
  },
  {
    groupFilterCode: 'demographic',
    label: intl.t('company'),
    modules: ['offices'],
    operation: new FilterContains(),
    value: 'company',
  },
  {
    groupFilterCode: 'source',
    label: intl.t('channel_id'),
    modelName: 'channel',
    modelLabel: 'name',
    modelValue: 'id',
    modules: ['CRM'],
    operation: new FilterIs({
      centerFilterKey: 'center_id',
      values: [
        {
          label: intl.t('name'),
          value: 'value',
          type: 'search',
        },
      ],
    }),
    value: 'channels',
  },
  {
    groupFilterCode: 'behaviour',
    label: intl.t('campaign_id'),
    modules: ['CRM'],
    modelName: 'campaign',
    modelLabel: 'name',
    modelValue: 'id',
    operation: new FilterIs({
      values: [
        {
          label: intl.t('name'),
          value: 'value',
          type: 'search',
        },
      ],
    }),
    value: 'campaigns',
  },
  {
    groupFilterCode: 'loyalty',
    label: intl.t('user.commerce_activity_scan'),
    modules: ['CRM'],
    modelName: 'commerce',
    modelLabel: 'name',
    modelValue: 'id',
    operation: new FilterIs({
      centerFilterKey: 'center_id',
      values: [
        {
          label: intl.t('name'),
          value: 'commerce',
          type: 'search',
        },
      ],
    }),
    value: 'commerce_activity_scan',
  },
  {
    groupFilterCode: 'loyalty',
    label: intl.t('user.multi_exchange_coupon'),
    modelName: 'proposal',
    modelLabel: 'name',
    modelValue: 'id',
    modules: ['CRM'],
    operation: new FilterBetween({
      customFilter: {
        is_coupon: [1],
      },
      values: [
        {
          type: 'number',
          value: 'min',
        },
        {
          type: 'static-text',
          label: intl.t('and'),
        },
        {
          type: 'number',
          value: 'max',
        },
        {
          type: 'static-text',
          label: intl.t('user.times'),
        },
        {
          type: 'static-text',
          label: intl.t('user.in-proposal'),
        },
        {
          label: intl.t('value'),
          type: 'search',
          value: 'proposal',
        },
      ],
    }),
    value: 'multi_exchange_coupon',
  },
  {
    groupFilterCode: 'loyalty',
    label: intl.t('user.redeemed-loyalty-cards-range'),
    modelName: 'campaign',
    modelLabel: 'name',
    modelValue: 'id',
    modules: ['CRM'],
    operation: new FilterBetween({
      resultFilterBy: 'isLoyaltyCard',
      values: [
        {
          type: 'number',
          value: 'min',
        },
        {
          type: 'static-text',
          label: intl.t('and'),
        },
        {
          type: 'number',
          value: 'max',
        },
        {
          type: 'static-text',
          label: intl.t('user.redeemed-loyalty-cards'),
        },
        {
          type: 'static-text',
          label: intl.t('user.in-campaign'),
        },
        {
          label: intl.t('value'),
          type: 'search',
          value: 'value',
        },
      ],
    }),
    value: 'loyalty_card_campaign',
  },
  {
    groupFilterCode: 'loyalty',
    label: intl.t('user.commerce_participations_generated'),
    modelName: 'commerce',
    modelLabel: 'name',
    modelValue: 'id',
    modules: ['CRM'],
    operation: new FilterBetween({
      centerFilterKey: 'center_id',
      values: [
        {
          type: 'number',
          value: 'min',
        },
        {
          type: 'static-text',
          label: intl.t('and'),
        },
        {
          type: 'number',
          value: 'max',
        },
        {
          type: 'static-text',
          label: intl.t('user.number-of-participations'),
        },
        {
          type: 'static-text',
          label: intl.t('user.in-comerce'),
        },
        {
          label: intl.t('value'),
          type: 'search',
          value: 'commerce',
        },
      ],
    }),
    value: 'commerce_participations_generated',
  },
  {
    groupFilterCode: 'loyalty',
    label: intl.t('user.campaign_participations_count_in_commerces'),
    modelName: 'campaign',
    modelLabel: 'name',
    modelValue: 'id',
    modules: ['CRM'],
    operation: new FilterBetween({
      resultFilterBy: 'isLoyaltyCard',
      values: [
        {
          type: 'number',
          value: 'participations_min',
        },
        {
          type: 'static-text',
          label: intl.t('and'),
        },
        {
          type: 'number',
          value: 'participations_max',
        },
        {
          type: 'static-text',
          label: intl.t('user.number-of-participations'),
        },
        {
          type: 'static-text',
          label: intl.t('user.in-campaign'),
        },
        {
          label: intl.t('value'),
          type: 'search',
          value: 'campaign',
        },
        {
          type: 'static-text',
          label: intl.t('in'),
        },
        {
          type: 'number',
          value: 'commerces_min',
        },
        {
          type: 'static-text',
          label: intl.t('and'),
        },
        {
          type: 'number',
          value: 'commerces_max',
        },
        {
          type: 'static-text',
          label: intl.t('commerces'),
        },
      ],
    }),
    value: 'campaign_participations_count_in_commerces',
  },
  {
    groupFilterCode: 'loyalty',
    label: intl.t('user.redeemable-stamps-range'),
    modelName: 'campaign',
    modelLabel: 'name',
    modelValue: 'id',
    modules: ['CRM'],
    operation: new FilterBetween({
      resultFilterBy: 'isLoyaltyCard',
      values: [
        {
          type: 'number',
          value: 'min',
        },
        {
          type: 'static-text',
          label: intl.t('and'),
        },
        {
          type: 'number',
          value: 'max',
        },
        {
          type: 'static-text',
          label: intl.t('user.redeemable-stamps'),
        },
        {
          type: 'static-text',
          label: intl.t('user.in-campaign'),
        },
        {
          label: intl.t('value'),
          type: 'search',
          value: 'value',
        },
      ],
    }),
    value: 'loyalty_redeemable_tickets',
  },
  {
    groupFilterCode: 'loyalty',
    label: intl.t('user.redeemed-reward-days'),
    modelName: 'campaign',
    modelLabel: 'name',
    modelValue: 'id',
    modules: ['CRM'],
    operation: new FilterBetween({
      resultFilterBy: 'isReward',
      values: [
        {
          type: 'number',
          value: 'min',
        },
        {
          type: 'static-text',
          label: intl.t('and'),
        },
        {
          type: 'number',
          value: 'max',
        },
        {
          type: 'static-text',
          label: intl.t('user.days-until-campaign-expiration'),
        },

        {
          label: intl.t('value'),
          type: 'search',
          value: 'value',
        },
      ],
    }),
    value: 'campaign_redeemable_rewards',
  },
  {
    groupFilterCode: 'loyalty',
    label: intl.t('user.received-reward-days'),
    modelName: 'campaign',
    modelLabel: 'name',
    modelValue: 'id',
    modules: ['CRM'],
    operation: new FilterIs({
      values: [
        {
          label: intl.t('in_the_last'),
          type: 'static-text',
        },
        {
          type: 'number',
          value: 'days',
        },
        {
          label: intl.t('days-in'),
          type: 'static-text',
        },
        {
          type: 'search',
          value: 'campaign',
        },
        {
          label: intl.t('user.filter-acticity'),
          type: 'static-text',
        },
      ],
      options: receivesFilterIs,
    }),
    value: 'campaign_rewards_received_days',
  },
  {
    groupFilterCode: 'behaviour',
    label: intl.t('tags'),
    modules: ['CRM', 'CMS'],
    modelName: 'userType',
    modelLabel: 'label',
    modelValue: 'id',
    operation: new FilterIs({
      centerFilterKey: 'center-id',
      values: [
        {
          label: intl.t('name'),
          value: 'value',
          type: 'search',
        },
      ],
    }),
    value: 'usertypes',
  },
  {
    groupFilterCode: 'sales',
    label: intl.t('spending_receipts'),
    modules: ['CRM', 'tickets'],
    operation: new FilterOrMore({
      appendDates: 'Date',

      values: [
        {
          label: intl.t('value'),
          type: 'number',
          value: 'value',
        },
        {
          label: intl.t('€'),
          type: 'static-text',
        },
        {
          label: intl.t('between'),
          type: 'static-text',
        },
      ],
    }),
    value: 'spending_receipts',
  },
  {
    groupFilterCode: 'sales',
    label: intl.t('spending_receipts_number'),
    modules: ['CRM', 'tickets'],
    operation: new FilterOrMore({
      appendDates: 'Date',

      values: [
        {
          label: intl.t('value'),
          type: 'number',
          value: 'value',
        },
        {
          label: intl.t('crm.filters-section.receipts'),
          type: 'static-text',
        },
        {
          label: intl.t('between'),
          type: 'static-text',
        },
      ],
    }),
    value: 'spending_receipts_number',
  },
  {
    groupFilterCode: 'dates',
    label: intl.t('user.seniority'),
    modules: ['CRM'],
    operation: new FilterOrMore({
      values: [
        {
          label: intl.t('value'),
          type: 'number',
          value: 'value',
        },
        {
          label: intl.t('days'),
          type: 'static-text',
        },
      ],
    }),
    value: 'seniority',
  },
  {
    groupFilterCode: 'sales',
    label: intl.t('user.spending-receipt-days'),
    modules: ['CRM'],
    operation: new FilterBetween({
      values: [
        {
          type: 'number',
          value: 'min',
        },
        {
          type: 'static-text',
          label: intl.t('€'),
        },
        {
          type: 'number',
          value: 'max',
        },
        {
          type: 'static-text',
          label: intl.t('€'),
        },
        {
          type: 'static-text',
          label: intl.t('between'),
        },
        {
          type: 'number',
          value: 'min_days',
        },
        {
          type: 'static-text',
          label: intl.t('and'),
        },
        {
          type: 'number',
          value: 'max_days',
        },
        {
          type: 'static-text',
          label: intl.t('days-ago'),
        },
      ],
    }),
    value: 'spending_receipts_days',
  },
  {
    groupFilterCode: 'sales',
    label: intl.t('user.number-receipt-days'),
    modules: ['CRM'],
    operation: new FilterBetween({
      values: [
        {
          type: 'number',
          value: 'min',
        },
        {
          type: 'static-text',
          label: intl.t('and'),
        },
        {
          type: 'number',
          value: 'max',
        },
        {
          type: 'static-text',
          label: intl.t('receipts'),
        },
        {
          type: 'static-text',
          label: intl.t('between'),
        },
        {
          type: 'number',
          value: 'min_days',
        },
        {
          type: 'static-text',
          label: intl.t('and'),
        },
        {
          type: 'number',
          value: 'max_days',
        },
        {
          type: 'static-text',
          label: intl.t('days-ago'),
        },
      ],
    }),
    value: 'number_receipts_days',
  },
  {
    groupFilterCode: 'sales',
    label: intl.t('user.most-purchased-subfamily'),
    model: 'retailProductSubfamilies',
    modelLabel: 'description',
    modelValue: 'id',
    modules: ['CRM'],
    operation: new FilterIs({
      values: [
        {
          label: intl.t('value'),
          type: 'select',
          value: 'value',
        },
        {
          label: intl.t('between'),
          type: 'static-text',
        },
        {
          type: 'number',
          value: 'min_days',
        },
        {
          type: 'static-text',
          label: intl.t('and'),
        },
        {
          type: 'number',
          value: 'max_days',
        },
        {
          type: 'static-text',
          label: intl.t('days-ago'),
        },
      ],
    }),
    value: 'most_purchased_subfamily',
  },
  {
    groupFilterCode: 'sales',
    label: intl.t('user.most-purchased-brand-days'),
    modelName: 'retailBrand',
    modelLabel: 'description',
    modelValue: 'id',
    modules: ['CRM'],
    operation: new FilterOrMore({
      centerFilterKey: 'center_id',
      values: [
        {
          label: intl.t('value'),
          type: 'number',
          value: 'value',
        },
        {
          label: intl.t('items-form'),
          type: 'static-text',
        },
        {
          label: intl.t('value'),
          type: 'search',
          value: 'brand_id',
        },
        {
          label: intl.t('in_the_last'),
          type: 'static-text',
        },
        {
          type: 'number',
          value: 'days',
        },
        {
          label: intl.t('days'),
          type: 'static-text',
        },
      ],
    }),
    value: 'bought_brand_last_days',
  },
  {
    groupFilterCode: 'sales',
    label: intl.t('user.most-purchased-brand-dates'),
    modelName: 'retailBrand',
    modelLabel: 'description',
    modelValue: 'id',
    modules: ['CRM'],
    operation: new FilterOrMore({
      appendDates: 'Date',
      centerFilterKey: 'center_id',
      values: [
        {
          label: intl.t('value'),
          type: 'number',
          value: 'value',
        },
        {
          label: intl.t('items-form'),
          type: 'static-text',
        },
        {
          label: intl.t('value'),
          type: 'search',
          value: 'brand_id',
        },
        {
          label: intl.t('between'),
          type: 'static-text',
        },
      ],
    }),
    value: 'bought_brand_dates',
  },
  {
    groupFilterCode: 'behaviour',
    label: intl.t('user.segment'),
    modelName: 'segment',
    modelLabel: 'segmentName',
    modelValue: 'id',
    modules: ['CRM'],
    operation: new FilterIs({
      centerFilterKey: 'center_id',
      values: [
        {
          label: intl.t('name'),
          value: 'value',
          type: 'search',
        },
      ],
    }),
    value: 'segments',
  },
  {
    groupFilterCode: 'behaviour',
    label: intl.t('user.app-activity-between-days'),
    modules: ['CRM'],
    operation: new FilterBetween({
      values: [
        {
          label: intl.t('value'),
          type: 'number',
          value: 'min_days',
        },
        {
          label: intl.t('and'),
          type: 'static-text',
        },
        {
          label: intl.t('value'),
          type: 'number',
          value: 'max_days',
        },
        {
          label: intl.t(
            'crm.days-ago',
          ),
          type: 'static-text',
        },
      ],
    }),
    value: 'app_activity_last_days',
  },
  {
    groupFilterCode: 'behaviour',
    label: intl.t('user.users-state-on-club'),
    model: 'registerStates',
    modelLabel: 'label',
    modelValue: 'value',
    modules: ['CRM'],
    operation: new FilterIs(),
    value: 'register_state',
  },
  {
    groupFilterCode: 'loyalty',
    label: intl.t('coupon-used'),
    modelName: 'coupon',
    modelLabel: 'text',
    modelValue: 'id',
    modules: ['CMS'],
    operation: new FilterIs({
      values: [
        {
          label: intl.t('name'),
          value: 'value',
          type: 'search',
        },
      ],
    }),
    value: 'coupons',
  },
  {
    groupFilterCode: 'email',
    label: intl.t('user.email-opened-days-ago'),
    modules: ['CRM'],
    operation: new FilterBetween({
      values: [
        {
          label: intl.t('value'),
          type: 'number',
          value: 'min_days',
        },
        {
          label: intl.t('and'),
          type: 'static-text',
        },
        {
          label: intl.t('value'),
          type: 'number',
          value: 'max_days',
        },
        {
          label: intl.t(
            'crm.days-ago',
          ),
          type: 'static-text',
        },
      ],
    }),
    value: 'email_opened_last_days',
  },
  {
    groupFilterCode: 'behaviour',
    label: intl.t('crm.filters-section.activity-dates'),
    modules: ['CRM'],
    operation: new FilterBetween({
      appendDates: 'Date',
    }),
    value: 'activity_dates',
  },
  {
    groupFilterCode: 'behaviour',
    label: intl.t('user.participations-activity-between-days'),
    modules: ['CRM'],
    operation: new FilterBetween({
      values: [
        {
          label: intl.t('value'),
          type: 'number',
          value: 'min_days',
        },
        {
          label: intl.t('and'),
          type: 'static-text',
        },
        {
          label: intl.t('value'),
          type: 'number',
          value: 'max_days',
        },
        {
          label: intl.t(
            'crm.days-ago',
          ),
          type: 'static-text',
        },
      ],
    }),
    value: 'participations_activity_last_days',
  },
  {
    groupFilterCode: 'behaviour',
    label: intl.t('inactivity_days'),
    modules: ['CRM'],
    operation: new FilterOrMore({
      values: [
        {
          label: intl.t('value'),
          type: 'number',
          value: 'value',
        },
        {
          label: intl.t('days'),
          type: 'static-text',
        },
      ],
    }),
    value: 'inactivity_days',
  },
  {
    groupFilterCode: 'behaviour',
    label: intl.t('activity_days'),
    modules: ['CRM'],
    operation: new FilterOrMore({
      values: [
        {
          label: intl.t('value'),
          type: 'number',
          value: 'value',
        },
        {
          label: intl.t('days'),
          type: 'static-text',
        },
      ],
    }),
    value: 'activity_days',
  },
  {
    groupFilterCode: 'loyalty',
    label: intl.t('crm.filters-section.campaign-participations-from-to'),
    modelName: 'campaign',
    modelLabel: 'name',
    modelValue: 'id',
    modules: ['CRM'],
    operation: new FilterBetween({
      values: [
        {
          label: intl.t('value'),
          type: 'number',
          value: 'min',
        },
        {
          label: intl.t('and'),
          type: 'static-text',
        },
        {
          label: intl.t('value'),
          type: 'number',
          value: 'max',
        },
        {
          label: intl.t(
            'crm.filters-section.campaign-participations-in-activity',
          ),
          type: 'static-text',
        },
        {
          type: 'search',
          value: 'campaign',
        },
      ],
    }),
    value: 'campaign_participations_count',
  },
  {
    groupFilterCode: 'email',
    label: intl.t('crm.filters-section.email_engagement_last_days'),
    modules: ['CRM'],
    operation: new FilterCustomOperation({
      options: UserEmailEngagementOptions,
      values: [
        {
          label: intl.t('value'),
          type: 'number',
          value: 'value',
        },
        {
          label: intl.t('days-before'),
          type: 'static-text',
        },
      ],
    }),
    value: 'email_engagement_last_days',
  },
  {
    groupFilterCode: 'email',
    label: intl.t('crm.filters-section.email_engagement_campaign'),
    modules: ['CRM'],
    modelName: 'emailCampaign',
    modelLabel: 'name',
    modelValue: 'id',
    operation: new FilterCustomOperation({
      options: UserEmailEngagementOptions,
      values: [
        {
          label: intl.t('in'),
          type: 'static-text',
        },
        {
          label: intl.t('campaign_id'),
          value: 'value',
          type: 'search',
        },
      ],
    }),
    value: 'email_engagement_campaign',
  },
  {
    groupFilterCode: 'email',
    label: intl.t('crm.filters-section.email_engagement_dates'),
    modules: ['CRM'],
    modelLabel: 'name',
    modelValue: 'id',
    operation: new FilterCustomOperation({
      options: UserEmailEngagementOptions,
      values: [
        {
          label: intl.t('between'),
          type: 'static-text',
        },
        {
          type: 'date',
          label: intl.t('start'),
          value: 'start_date',
        },
        {
          type: 'date',
          label: intl.t('end'),
          value: 'end_date',
        },
      ],
    }),
    value: 'email_engagement_dates',
  },
  {
    groupFilterCode: 'data_quality',
    label: intl.t('without_channel'),
    model: 'channels',
    modelLabel: 'name',
    modelValue: 'id',
    modules: ['CRM'],
    operation: new FilterBoolean(),
    value: 'without_channel',
  },
  {
    groupFilterCode: 'data_quality',
    label: intl.t('without_phone'),
    modules: ['CRM'],
    operation: new FilterBoolean(),
    value: 'without_phone',
  },
  {
    groupFilterCode: 'data_quality',
    label: intl.t('without_cp'),
    modules: ['CRM'],
    operation: new FilterBoolean(),
    value: 'without_cp',
  },
  {
    groupFilterCode: 'data_quality',
    label: intl.t('without_bdate'),
    modules: ['CRM'],
    operation: new FilterBoolean(),
    value: 'without_bdate',
  },
  {
    groupFilterCode: 'data_quality',
    label: intl.t('without_dni'),
    modules: ['CRM'],
    operation: new FilterBoolean(),
    value: 'without_dni',
  },
  {
    groupFilterCode: 'data_quality',
    label: intl.t('without_name'),
    modules: ['CRM'],
    operation: new FilterBoolean(),
    value: 'without_name',
  },
  {
    groupFilterCode: 'data_quality',
    label: intl.t('without_sex'),
    modules: ['CRM'],
    operation: new FilterBoolean(),
    value: 'without_sex',
  },
  {
    groupFilterCode: 'proposals',
    label: intl.t('crm.filters-section.proposals-engagement-last-days'),
    modules: ['CRM'],
    operation: new FilterCustomOperation({
      options: UserProposalEngagementOptions,
      values: [
        {
          label: intl.t('value'),
          type: 'number',
          value: 'value',
        },
        {
          label: intl.t('days-before'),
          type: 'static-text',
        },
      ],
    }),
    value: 'proposal_engagement_last_days',
  },
  {
    groupFilterCode: 'proposals',
    label: intl.t('crm.filters-section.proposals-engagement-campaign'),
    modules: ['CRM'],
    modelName: 'proposal',
    modelLabel: 'name',
    modelValue: 'id',
    operation: new FilterCustomOperation({
      options: UserProposalEngagementOptions,
      values: [
        {
          label: intl.t('in'),
          type: 'static-text',
        },
        {
          label: intl.t('proposal'),
          value: 'value',
          type: 'search',
        },
      ],
    }),
    value: 'proposal_engagement',
  },
  {
    groupFilterCode: 'proposals',
    label: intl.t('crm.filters-section.proposals-engagement-dates'),
    modules: ['CRM'],
    model: 'proposals',
    modelLabel: 'name',
    modelValue: 'id',
    operation: new FilterCustomOperation({
      options: UserProposalEngagementOptions,
      values: [
        {
          label: intl.t('between'),
          type: 'static-text',
        },
        {
          type: 'date',
          label: intl.t('start'),
          value: 'start_date',
        },
        {
          label: intl.t('and'),
          type: 'static-text',
        },
        {
          type: 'date',
          label: intl.t('end'),
          value: 'end_date',
        },
      ],
    }),
    value: 'proposal_engagement_dates',
  },
  {
    groupFilterCode: 'proposals',
    label: intl.t('crm.filters-section.proposals-engagement-category'),
    modules: ['CRM'],
    modelName: 'category',
    modelLabel: 'fullRoute',
    modelValue: 'id',
    operation: new FilterCustomOperation({
      options: UserProposalEngagementOptions,
      centerFilterKey: 'center_id',
      values: [
        {
          label: intl.t('days'),
          type: 'number',
          value: 'days',
        },
        {
          label: intl.t('days-before'),
          type: 'static-text',
        },
        {
          label: intl.t('with-category'),
          type: 'static-text',
        },
        {
          label: intl.t('name'),
          value: 'value',
          type: 'search',
        },
      ],
    }),
    value: 'proposal_engagement_category_last_days',
  },
  {
    groupFilterCode: 'sales',
    label: intl.t('crm.filters-section.created-receipts-commerce'),
    modules: ['CRM'],
    modelName: 'commerce',
    modelLabel: 'name',
    modelValue: 'id',
    operation: new FilterIs({
      centerFilterKey: 'center_id',
      onlyPositive: true,
      values: [
        {
          label: intl.t('crm.filters-section.associated_to'),
          type: 'static-text',
        },
        {
          label: intl.t('value'),
          type: 'search',
          value: 'value',
        },
      ],
    }),
    value: 'created_receipts_commerce',
  },
  {
    groupFilterCode: 'sales',
    label: intl.t('crm.filters-section.sales-engagement-coupons'),
    modules: ['CRM'],
    modelName: 'category',
    modelLabel: 'fullRoute',
    modelValue: 'id',
    operation: new FilterOrMore({
      appendDates: 'Date',
      centerFilterKey: 'center_id',
      values: [
        {
          label: intl.t('coupon-count'),
          type: 'number',
          value: 'coupon_count',
        },
        {
          label: intl.t('with-category'),
          type: 'static-text',
        },
        {
          label: intl.t('name'),
          value: 'value',
          type: 'search',
        },
        {
          label: intl.t('between'),
          type: 'static-text',
        },
      ],
    }),
    value: 'sales_coupons_in_category_range_date',
  },
  {
    groupFilterCode: 'behaviour',
    label: intl.t('crm.filters-section.campaign-participations-between-dates'),
    modelName: 'campaign',
    modelLabel: 'name',
    modelValue: 'id',
    modules: ['CRM'],
    operation: new FilterBetween({
      appendDates: 'Date',
    }),
    value: 'participation_dates',
  },
  {
    groupFilterCode: 'loyalty',
    label: intl.t('crm.filters-section.campaign-participations-between-dates'),
    modelName: 'campaign',
    modelLabel: 'name',
    modelValue: 'id',
    modules: ['CRM'],
    operation: new FilterBetween({
      appendDates: 'Date',
    }),
    value: 'participation_dates',
  },
  {
    groupFilterCode: 'loyalty',
    label: intl.t('crm.filters-section.campaign-participations'),
    modelName: 'campaign',
    modelLabel: 'name',
    modelValue: 'id',
    modules: ['CRM'],
    operation: new FilterOrMore({
      appendDates: 'Date',

      values: [
        {
          label: intl.t('value'),
          type: 'number',
          value: 'value',
        },
        {
          label: intl.t('in'),
          type: 'static-text',
        },
        {
          label: intl.t('value'),
          type: 'search',
          value: 'campaign',
        },
        {
          label: intl.t('between'),
          type: 'static-text',
        },
      ],
    }),
    value: 'campaign_participations',
  },
  {
    groupFilterCode: 'loyalty',
    label: intl.t(
      'crm.filters-section.campaign-participations-from-to-last-days',
    ),
    modelName: 'campaign',
    modelLabel: 'name',
    modelValue: 'id',
    modules: ['CRM'],
    operation: new FilterBetween({
      values: [
        {
          label: intl.t('value'),
          type: 'number',
          value: 'min',
        },
        {
          label: intl.t('and'),
          type: 'static-text',
        },
        {
          label: intl.t('value'),
          type: 'number',
          value: 'max',
        },
        {
          label: intl.t(
            'crm.filters-section.campaign-participations-in-activity',
          ),
          type: 'static-text',
        },
        {
          type: 'search',
          value: 'campaign',
        },
        {
          label: intl.t('last'),
          type: 'static-text',
        },
        {
          label: intl.t('value'),
          type: 'number',
          value: 'days',
        },
        {
          label: intl.t('days'),
          type: 'static-text',
        },
      ],
    }),
    value: 'campaign_participations_count_last_days',
  },
  {
    groupFilterCode: 'behaviour',
    label: intl.t('crm.filters-section.campaign-participations-days'),
    modelName: 'campaign',
    modelLabel: 'name',
    modelValue: 'id',
    modules: ['CRM'],
    operation: new FilterOrMore({
      values: [
        {
          label: intl.t('value'),
          type: 'number',
          value: 'value',
        },
        {
          label: intl.t('days'),
          type: 'static-text',
        },
      ],
    }),
    value: 'participation_days',
  },
  {
    groupFilterCode: 'push_notifications_engagement',
    label: intl.t('push-notifications'),
    modules: ['CRM'],
    modelName: 'proposal',
    modelLabel: 'name',
    modelValue: 'id',
    operation: new FilterCustomOperation({
      options: [
        {
          label: intl.t('opened'),
          value: 'is',
        },
        {
          label: intl.t('not_opened'),
          value: 'is_not',
        },
      ],
      values: [
        {
          label: intl.t('in_proposal'),
          type: 'static-text',
        },
        {
          label: intl.t('proposal'),
          value: 'value',
          type: 'search',
        },
      ],
    }),
    value: 'user_opened_push_in_proposal',
  },
  {
    groupFilterCode: 'push_notifications_engagement',
    label: intl.t('push-notifications-between-dates'),
    modules: ['CRM'],
    operation: new FilterBetween({
      appendDates: 'Date',
    }),
    value: 'user_opened_push_between_dates',
  },
  {
    groupFilterCode: 'push_notifications_engagement',
    label: intl.t('push-notifications-seniority'),
    modules: ['CRM'],
    operation: new FilterOrMore({
      values: [
        {
          label: intl.t('value'),
          type: 'number',
          value: 'value',
        },
        {
          label: intl.t('days-before'),
          type: 'static-text',
        },
      ],
    }),
    value: 'user_opened_push_last_days',
  },
  {
    groupFilterCode: 'foreground_location',
    label: intl.t('foreground-location.cp'),
    modules: ['foregroundLocation'],
    operation: new FilterIs({
      values: [
        {
          type: 'number',
          value: 'value',
        },
      ],
    }),
    value: 'calculated_postal_code_code',
  },
  {
    groupFilterCode: 'foreground_location',
    label: intl.t('foreground-location.income'),
    modules: ['foregroundLocation'],
    operation: new FilterBetween({
      values: [
        {
          type: 'number',
          value: 'min',
        },
        {
          label: intl.t('and'),
          type: 'static-text',
        },
        {
          type: 'number',
          value: 'max',
        },
      ],
    }),
    value: 'calculated_postal_code_income',
  },
]);
