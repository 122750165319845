export default Object.freeze([
  {
    label: intl.t('all'),
    value: 'and',
  },
  {
    label: intl.t('any-of'),
    value: 'or',
  },
]);
