export default Object.freeze([
  {
    label: intl.t('campaign-types.comunication'),
    value: 'comunication',
  },
  {
    label: intl.t('campaign-types.digital'),
    value: 'digital',
  },
  {
    label: intl.t('campaign-types.event'),
    value: 'event',
  },
  {
    label: intl.t('campaign-types.loyalty'),
    value: 'loyalty',
  },
  {
    label: intl.t('campaign-types.others'),
    value: 'others',
  },
  {
    label: intl.t('campaign-types.promotion'),
    value: 'promotion',
  },
  {
    label: intl.t('campaign-types.rrpp'),
    value: 'rrpp',
  },
  {
    label: intl.t('campaign-types.service'),
    value: 'service',
  },
  {
    label: intl.t('campaign-types.vip-service'),
    value: 'vipservice',
  },
]);
