export default Object.freeze([
  {
    label: intl.t('wifi-security-type.open'),
    value: 'OPEN',
  },
  {
    label: intl.t('wifi-security-type.wep'),
    value: 'WEP',
  },
  {
    label: intl.t('wifi-security-type.wpa'),
    value: 'WPA',
  },
  {
    label: intl.t('wifi-security-type.peap'),
    value: 'PEAP',
  },
]);
