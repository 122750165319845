import FilterBoolean from 'neuro-frontend/utils/filter-operation/filter-boolean';

export default [
  {
    groupName: intl.t('crm.tags.filters'),
    options: [
      {
        label: intl.t('crm.tags.is-interest'),
        modules: ['CRM'],
        operation: new FilterBoolean(),
        value: 'interest',
      },
    ],
  },
];
