import FilterIs from 'neuro-frontend/utils/filter-operation/filter-is';
import staffLevels from 'neuro-frontend/enums/staff-levels';

export default Object.freeze([
  {
    groupName: intl.t('crm.filters-section.source'),
    options: [
      {
        label: intl.t('level'),
        model: staffLevels(),
        modelLabel: 'label',
        modelValue: 'value',
        operation: new FilterIs(),
        value: 'level',
      },
      {
        label: intl.t('center'),
        model: 'centers',
        modelLabel: 'name',
        modelValue: 'id',
        operation: new FilterIs(),
        value: 'center_id',
      },
    ],
  },
]);
