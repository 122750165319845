export default Object.freeze([
  {
    label: intl.t('nif'),
    value: 'nif',
  },
  {
    label: intl.t('cif'),
    value: 'cif',
  },
  {
    label: intl.t('residence_card'),
    value: 'residence_card',
  },
  {
    label: intl.t('passport'),
    value: 'passport',
  },
]);
