export default Object.freeze([
  {
    label: intl.t('north'),
    value: 'N',
  },
  {
    label: intl.t('south'),
    value: 'S',
  },
  {
    label: intl.t('east'),
    value: 'E',
  },
  {
    label: intl.t('west'),
    value: 'W',
  },
]);
