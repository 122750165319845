export default Object.freeze([
  {
    label: intl.t('child'),
    value: 'child',
  },
  {
    label: intl.t('nephew'),
    value: 'nephew_niece',
  },
  {
    label: intl.t('grandchild'),
    value: 'grandchild',
  },
  {
    label: intl.t('others'),
    value: 'others',
  },
]);
