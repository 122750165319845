export default Object.freeze([
  {
    label: intl.t('draft'),
    value: 'draft',
  },
  {
    label: intl.t('published'),
    value: 'published',
  },
  {
    label: intl.t('bookable'),
    value: 'bookable',
  },
  {
    label: intl.t('draft-bookable'),
    value: 'draft-bookable',
  },
]);
