import FilterIs from 'neuro-frontend/utils/filter-operation/filter-is';
import FilterBetween from 'neuro-frontend/utils/filter-operation/filter-between';
import emailcampaign from './emailing-campaign-status';

export default [
  {
    groupName: intl.t('emailing-campaign.filters'),
    options: [
      {
        label: intl.t('date'),
        operation: new FilterBetween({
          appendDates: 'Datetime',
        }),
        value: 'date',
      },
      {
        label: intl.t('status'),
        model: emailcampaign,
        operation: new FilterIs({
          onlyPositive: true,
        }),
        value: 'state',
      },
    ],
  },
];
