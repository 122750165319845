export default Object.freeze([
  {
    label: intl.t('man'),
    value: 'H',
  },
  {
    label: intl.t('woman'),
    value: 'M',
  },
  {
    label: intl.t('sexes.do-not-want-to-say'),
    value: '!A',
  },
  {
    label: intl.t('other'),
    value: 'O',
  },
]);
