export default Object.freeze([
  {
    label: intl.t('intranet.sovos.pending'),
    value: 'pending',
    color: '#8c9eff',
  },
  {
    label: intl.t('intranet.sovos.imported'),
    value: 'imported',
    color: '#548C2F',
  },
  {
    label: intl.t('intranet.sovos.invalid'),
    value: 'invalid',
    color: '#E57373',
    tooltip: intl.t('sovos.file.status.invalid.tooltip'),
  },
  {
    label: intl.t('intranet.sovos.deleted'),
    value: 'deleted',
    color: '#F1A66A',
    tooltip: intl.t('sovos.file.status.deleted.tooltip'),
  },
  {
    label: intl.t('intranet.sovos.no_data'),
    value: 'no_data',
    color: '#fbc02d',
    tooltip: intl.t('sovos.file.status.no_data.tooltip'),
  },
]);
