export default Object.freeze([
  {
    label: intl.t('boy'),
    value: 'H',
  },
  {
    label: intl.t('girl'),
    value: 'M',
  },
]);
