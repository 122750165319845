import FilterIs from 'neuro-frontend/utils/filter-operation/filter-is';

export default [
  {
    groupName: intl.t('proposal.filters'),
    options: [
      {
        label: intl.t('crm.commerce-types'),
        model: 'commerceTypes',
        modelName: 'commerceType',
        modelLabel: 'label',
        modelValue: 'code',
        labelKey: 'label',
        valueKey: 'id',
        operation: new FilterIs({
          centerFilterKey: 'center_id',
          onlyPositive: true,
          values: [
            {
              label: intl.t('name'),
              value: 'value',
              type: 'search',
            },
          ],
        }),
        value: 'commercetype',
      },
      {
        label: intl.t('categories'),
        model: 'categories',
        modelName: 'category',
        modelLabel: 'name',
        modelValue: 'id',
        operation: new FilterIs({
          centerFilterKey: 'center_id',
          onlyPositive: true,
          values: [
            {
              label: intl.t('name'),
              value: 'value',
              type: 'search',
            },
          ],
        }),
        value: 'category',
      },
    ],
  },
];
