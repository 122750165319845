export default Object.freeze([
  {
    label: intl.t('single'),
    value: 'S',
  },
  {
    label: intl.t('couple'),
    value: 'P',
  },
  {
    label: intl.t('family'),
    value: 'F',
  },
  {
    label: intl.t('sexes.do-not-want-to-say'),
    value: '',
  },
]);
