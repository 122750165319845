export default Object.freeze([
  {
    key: 'playcenter',
    name: intl.t('modules-playcenter'),
  },
  {
    key: 'activities',
    name: intl.t('modules-activities'),
  },
  {
    key: 'sign',
    name: intl.t('modules-sign'),
  },
  {
    key: 'stays',
    name: intl.t('modules-stays'),
  },
  {
    key: 'sales',
    name: intl.t('modules-sales'),
  },
]);
