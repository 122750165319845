export default Object.freeze([
  {
    key: 'blog',
    name: intl.t('modules-blog'),
  },
  {
    key: 'cms',
    name: intl.t('modules-cms'),
  },
  {
    key: 'crm',
    name: intl.t('modules-crm'),
  },
  {
    key: 'intranet',
    name: intl.t('modules-intranet'),
  },
  {
    key: 'marketing',
    name: intl.t('modules-marketing'),
  },
  {
    key: 'movies',
    name: intl.t('modules-theaters'),
  },
  {
    key: 'multilevel',
    name: intl.t('modules-multilevel'),
  },
  {
    key: 'tally',
    name: intl.t('modules-tally'),
  },
  {
    key: 'connectif',
    name: intl.t('modules-connectif'),
  },
  {
    key: 'gymkhana',
    name: intl.t('modules-gymkhana'),
  },
  {
    key: 'app_operator',
    name: intl.t('app-operator'),
  },
  {
    key: 'totem_channel',
    name: intl.t('totem_channel'),
  },
  {
    key: 'proposal_inbox',
    name: intl.t('proposal_inbox'),
  },
]);
