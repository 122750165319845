export default [
  {
    label: intl.t('week'),
    value: 'week',
  },
  {
    label: intl.t('month'),
    value: 'month',
  },
  {
    label: intl.t('year'),
    value: 'year',
  },
];
